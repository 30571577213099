import Rest from '../Rest'

export default class CampaignService extends Rest {

  /**
   * @type {String}
   */
  static resource = '/api/v1/campaigns'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getCampaignByCode(code) {
    return this.get(`/code/${code}`)
  }

  createLead(campaingId: Number, params: any) {
    return this.post(`/${campaingId}/create_lead`, params).then(data => {
      return data
    })
  }

}
